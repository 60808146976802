const RDCalculatorFormDef = ()=> {
	return {
		title: 'RD Calculator',
		titleClass:'fa fa-calculator',
		description:'Our Recurring Deposit (RD) Calculator is designed to help you plan and optimize your savings effectively. By entering your monthly deposit amount, interest rate, and tenure, the calculator provides an accurate estimate of the maturity amount based on quarterly compounding. Additionally, it offers an "as of date" estimation, allowing you to see the current value of your RD if it were to mature on a specific date before the actual maturity date.',
        helperText:"With the flexibility to explore different deposit amounts and tenures, our RD Calculator helps you understand the impact of various saving strategies. Whether you're aiming for a short-term goal or a long-term financial objective, this tool provides valuable insights to maximize your savings potential.",
		gridStyle: {sm:1, md:4},
		fields:[
				
                            {
                                'type': 'number',
                                'id': 'contributionAmount',
                                'required':true,
                                'label':'Contribution Amount',
                                'placeholder': 'Contribution Amount',							
                                'validators':[
                                    {
                                        'name':'required',										
                                        'errorMessage':'Required Field'
                                    },
                                    {
                                        'name':'min',
                                        'value':'100',
                                        'errorMessage':'Amount Should be greater than 100'
                                    }
                                ]
                            },
							
							{
								
								'type': 'decimal',
								'id': 'interestRate',
                                'required':true,
								'label':'Interest Rate',
								'placeholder': 'Interest Rate',									
								'validators':[
									{
										'name':'required',										
										'errorMessage':'Required Field'
									},
									{
										'name':'min',
										'value':'0.01',
										'errorMessage':'Interest Rate should be in the range of 0.01 to 15'
									},
									{
										'name':'max',
										'value':'15',
										'errorMessage':'Interest Rate should be in the range of 0.01 to 15'
									},
									
								]
							},
							
							{
								
								'type': 'date',
								'id': 'startDate',
								'label':'Start Date',
                                'required':true,
								'placeholder': 'Start Date',							
								'validators':[
									{
										'name':'required',										
										'errorMessage':'Required Field'
									}
								]
							},
							{
								'size': {'xs':'12','sm':'12','md':'2'},
								'type': 'number',
								'id': 'duration',
                                'required':true,
								'label':'Tenure (In Months)',
								'placeholder': 'Tenure (In Months)',							
								'validators':[
									{
										'name':'required',										
										'errorMessage':'Required Field'
									},
									{
										'name':'min',
										'value':'6',
										'errorMessage':'The tenure must be between 6 and 120 months.'
									},
									{
										'name':'max',
										'value':'120',
										'errorMessage':'The tenure must be between 6 and 120 months.'
									},
								]
							}
					]
				
	}
}
export default RDCalculatorFormDef;