
const frequencies = [
    {label:'Monthly',value:1},
    {label:'Quarterly',value:3},
    {label:'Half Yearly',value:6},
    {label:'Yearly',value:12},

]

// const extensionOptions = [
//     {label:'No extension',value:0},
//     {label:'Extension with fresh contributions',value:1},
//     {label:'Extension without fresh contributions',value:2},
// ]

const extensionPeriods = [
    {label:'5 Years',value:5},
    {label:'10 Years',value:10},
    {label:'15 Years',value:15},
    {label:'20 Years',value:20},
    {label:'25 Years',value:25},
    {label:'30 Years',value:30},

]
const PPFCalculatorFormDef = (lastDateOfPreviousFY)=> {
	return {
		title: 'PPF Calculator',
		titleClass:'fa fa-calculator',
		description:'Our Public Provident Fund (PPF) Calculator is designed to help you plan and optimize your PPF investments effectively. By allowing you to specify the account opening date, contribution amount, contribution frequency, previous financial year-end closing balance, and extension periods in the block of five years, this tool provides detailed projections of your PPF balance over time. This feature is ideal for planning your retirement and making informed decisions about your financial future.',
		helperText:'Whether you are planning for long-term savings or considering extending your PPF account, this calculator enables you to explore various scenarios and assess the impact of different contribution strategies. With these insights, you can make informed decisions to maximize your returns and achieve your financial goals.',
		gridStyle: {sm:1, md:4},
		fields:[
			{
				'type': 'date',
				'id': 'startDate',
				'required':true,
				'label':'Account Open Date',
				'placeholder': 'Account Open Date',							
				'validators':[
					{
						'name':'required',										
						'errorMessage':'Required Field'
					}
				]
			},
            {
				'type': 'select',
				'id': 'contributionFrequency',
				'required':true,
				'label':'Contribution Frequency',
				'placeholder': 'Contribution Frequency',
                'options' :frequencies,							
				'validators':[
					{
						'name':'required',										
						'errorMessage':'Required Field'
					}
				]
			},
			{
				'type': 'number',
				'id': 'contributionAmount',
				'required':true,
				'label':'Contribution Amount',
				'placeholder': 'Contribution Amount',							
				'validators':[
					{
						'name':'required',										
						'errorMessage':'Required Field'
					},
					{
						'name':'min',
						'value':'500',
						'errorMessage':'Contribution Amount Should be greater than 500'
					}
				]
			},
			{
				'type': 'number',
				'id': 'balance',
				'required':true,
				'label':'Balance as of ' +  lastDateOfPreviousFY,
				'placeholder': 'Balance Amount',							
				'validators':[
					{
						'name':'required',										
						'errorMessage':'Required Field'
					},
					{
						'name':'min',
						'value':'0',
						'errorMessage':'Balance should not be less than 0'
					}
				]
			},
			// {
                
            //     'type': 'select',
            //     'id': 'extentionOption',
			// 	'required':true,
            //     'label':'Extension Option',
            //     'placeholder': 'Extension Option',	
            //     'options': extensionOptions,								
            //     'validators':[
            //         {
            //             'name':'required',										
            //             'errorMessage':'Required Field'
            //         },									
            //     ]
            // },
            {
                
                'type': 'select',
                'id': 'extentionPeriod',
                'label':'Extension Period',
                'placeholder': 'Extension Period',	
                'options': extensionPeriods,								
                
            },
            
			
	]
	}
}
export default PPFCalculatorFormDef;