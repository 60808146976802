import { Flex, Heading, Icon, Tooltip } from "@chakra-ui/react"
import { FcMoneyTransfer,FcLock, FcComboChart, FcCalendar, FcLibrary, FcAlarmClock, FcSynchronize, } from "react-icons/fc"
import { FaHandshake, FaPiggyBank,FaRegSmile,FaRegMoneyBillAlt } from "react-icons/fa"



const iconMap = {
    "Lock-in": {icon: <Icon ml={2} as={FcLock} w={5} h={5} />, tooltip:"Funds are locked for a period of time."},
    "Liquidity": {icon: <Icon ml={2} mt={0.9} as={FcMoneyTransfer} w={5} h={5} />, tooltip:"Ease of converting assets into cash"},
    "Guaranteed Returns": {icon: <Icon ml={2} as={FcComboChart} w={5} h={5} />,tooltip:"Low-risk investment"},
    "Flexible Tenure": {icon: <Icon ml={2} as={FcCalendar} w={5} h={5} />,tooltip:"Flexible investment period"},
    "Government-Backed": {icon: <Icon ml={2} as={FcLibrary} w={5} h={5} />,tooltip:"Government-backed scheme, safe & secure"},
    "Retirement Savings": {icon: <Icon ml={2} as={FaPiggyBank} w={5} h={5} color={"green.500"} />,tooltip:"Savings for retirement"},
    "Tax Benefit": {icon: <Icon ml={2} as={FaRegSmile} w={5} h={5} color={"pink.600"} />,tooltip:"Eligible for tax deductions under Section 80C"},
    "Mandatory Contribution": {icon: <Icon ml={2} as={FaHandshake} w={6} h={6} color={"yellow.600"} />,tooltip:"Both employee and employer contribute monthly to EPF."},
    "Long-Term": {icon: <Icon ml={2} as={FcAlarmClock} w={5} h={5} />,tooltip:"Long-term investment with a minimum tenure of 15 years."},
    "Extension": {icon: <Icon ml={2} as={FcSynchronize} w={5} h={5} />,tooltip:"Extension are allowed in blocks of 5 years"},
    "Flexible": {icon: <Icon ml={2} as={FaRegMoneyBillAlt} w={6} h={6}  color={"green.500"}/>,tooltip:"Flexible contribution between ₹500 and ₹1.5 lakh annually."},
}
export const CalculatorHeader = ({ title,highlights }) => {
    return (
        <Flex mb={-10} >
            
          
          <Heading   size="sm">{title}</Heading>
          {highlights.map((highlight, index) => (
            <Tooltip
              key={index}
              label={iconMap[highlight].tooltip}
              
              
            >
              <span >{iconMap[highlight].icon}</span>
            </Tooltip>
          ))}
          
        </Flex>

                

    )
}