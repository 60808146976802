import { Box, Card, CardBody, CardHeader, Flex, SimpleGrid, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { AppForm } from '../Forms/AppForm';
import { useRecoilState } from 'recoil';
import { loaderState } from '../State/Atoms';
import { BASE_URL } from '../Common/Constants';
import axios from 'axios';
import { useState } from 'react';
import { formatCurrency, formatDate, getMaturityDateHelperText } from '../Common/Utils';
import { Doughnut } from 'react-chartjs-2';
import {Chart, ArcElement,Tooltip,Legend} from 'chart.js'
import { ToWords } from 'to-words';
import FDCalculatorFormDef from '../Forms/Definations/FDFormDefination';
import { CalculationStats } from '../Common/CalculationStats';
import { CalculatorHeader } from '../Common/CalculatorHeader';
Chart.register(ArcElement);
Chart.register(Tooltip);
Chart.register(Legend);


export const FDCalculator = () => {
    const [, setLoader] = useRecoilState(loaderState);
    const [fdData, setFDData] = useState([]);
    const [chartData,setChartData] = useState({})
    const [dataArray1,setDataArray1] = useState([])
    const [dataArray2,setDataArray2] = useState([])
    const toWords = new ToWords();

    const onReset=()=>{
        setFDData([]);
        setChartData({})
        setDataArray1([])
        setDataArray2([])
    }
    
    const onSubmit = async(data) => {
        setLoader(true);
        try{
            const response = await axios.post(`${BASE_URL}/fi/calculators/fd`, data)
            setFDData(response.data);
            const investmentAmount = response.data.principalAmount;
            const interestAmount = response.data.interestAmount
            const maturityAmount = response.data.maturityAmount;
            const interestRate = response.data.interestRate;
            const maturityDate = response.data.maturityAsOfDate;
            const maturityAsOfDate = response.data.currentAmount;
            setChartData({investmentAmount,interestAmount,maturityAmount,interestRate})
            const maturityDateHelperText = getMaturityDateHelperText(new Date(maturityDate),data.startDate,maturityAsOfDate)
            setDataArray1([
                { name: 'Invested Amount',value: investmentAmount,formatter:formatCurrency,helperText:toWords.convert(investmentAmount,'INR')},
                { name: 'Amount As Of Today',value: maturityAsOfDate,formatter:formatCurrency,helperText:maturityDateHelperText},
               
            ])
            setDataArray2([
                
                { name: 'Maturity Amount',value: maturityAmount,formatter:formatCurrency,helperText:toWords.convert(maturityAmount,'INR')},
                { name: 'Maturity Date',value: maturityDate,formatter: formatDate}
            ])
        
        }catch(e){
           
        }

        setLoader(false);
       
       
    }
    const fdFormDef = FDCalculatorFormDef();
   
    
  

    return (
        <>
        <Card>
            <CardHeader>
                <CalculatorHeader title="Fixed Deposit Calculator" highlights={["Lock-in","Liquidity","Guaranteed Returns","Flexible Tenure"]} />
            </CardHeader>
            <CardBody>
                <Stack  spacing='5'>
                    <Text fontSize='md' color={'black.500'}>{fdFormDef.description}</Text>
                    <Text mb={5} fontSize='md' color={'black.500'}>{fdFormDef.helperText}</Text>
                    <AppForm  formDef={fdFormDef} onFormSubmit={onSubmit} onFormReset={onReset}/>
                </Stack>
            </CardBody>
        </Card>
        
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 2}}>
        { fdData && fdData.transactionList && fdData.transactionList.length > 0 && 
            <Box mt={10} mb={2} size="sm"  >
            <Flex width={["100%", "100%", "50%"]} height={["200px", "250px", "350px"]} >
                <DetailChart data={chartData} />
            </Flex>
        </Box> }
            { dataArray1 && dataArray2  && dataArray1.length > 0 && dataArray2.length > 0 && <Box>
                <CalculationStats data={dataArray1} /> 
                <CalculationStats data={dataArray2} /> 

            </Box>}
            
            
        </SimpleGrid>
        <Box mt={2} mb={2}>
        { fdData && fdData.transactionList && fdData.transactionList.length > 0 && <FDDetails data={fdData}/>}
        </Box>
        
       
        </>

    
    )
}

const DetailChart=({data})=>{
    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: 'start',
                labels: {
                    generateLabels: (chart) => {
                        const datasets = chart.data.datasets;
                        return datasets[0].data.map((data, i) => ({
                          text: `${chart.data.labels[i]} : ${formatCurrency(data)}`,
                          fillStyle: datasets[0].backgroundColor[i],
                          index: i
                        }))
                    },
                    textAlign: 'right'
                }
                
               
                    
            }
        }
    }
    
    const doughnut = {

       
        labels: [
          'Invested Amout',
          'Interest Amount'
         
        ],
        datasets: [
          {
            data: [data.investmentAmount, data.interestAmount],
            backgroundColor: [
              '#FF6384',
              '#17a2b8'
              
            ],
            hoverBackgroundColor: [
              '#FF6384',
              '#17a2b8',
             
            ],
          }],
      };
    return (
        <Doughnut data={doughnut} options={options}  />
    )
}


const FDDetails = ({data})=>{
    const headers= [
        {
            Header: 'Date',
            isNumeric: false,
        },
        {
            Header: 'Opening Balance',
            isNumeric: true
        },
        
        {
            Header: 'Interest Amount',
            isNumeric: true
        },
        {
            Header: 'Closing Balance',
            isNumeric: true
        }
    ]
    
    return (
        <TableContainer>
            <Table size='sm' variant={'striped'} colorScheme='gray' >
                <Thead>
                <Tr>  
                    {headers.map((header) => (
                    <Th key={header.Header} isNumeric={header.isNumeric}>{header.Header}</Th>
                    ))}
                </Tr>
                </Thead>
                <Tbody>
                {data.transactionList.map((transaction,index) => (
                    <Tr key={index}>
                    <Td>{formatDate(transaction.transactionDate)}</Td>
                    <Td isNumeric>{formatCurrency(transaction.openingBalance)}</Td>
                    <Td isNumeric>{formatCurrency(transaction.interestAmount)}</Td>
                    <Td isNumeric>{formatCurrency(transaction.closingBalance)}</Td>
                    </Tr>
                ))}
                
                </Tbody>
                
            </Table>
        </TableContainer>
    )
}