// 'use client'

import { Box, Text } from '@chakra-ui/react';
import { NavBar } from './NavBar';
import { Outlet } from 'react-router-dom';
import { Loader } from '../Common/Loader';

export default function DefaultLayout() {
  
  

  return (
    <Box minHeight="100vh" display="grid" gridTemplateRows="auto 1fr auto">
    <Box>
      <NavBar items={NAV_ITEMS} />
    </Box>
    <Box as="main"  p={4} display={{ base: 'block', md: 'none' }}>
      <Outlet />
    </Box>
    <Box as="main" mx={40} p={4} display={{ base: 'none', md: 'block' }}>
      <Outlet />
    </Box>
    <Box bg="gray.100" p={4}>
    <Box textAlign="center"  color="gray.500">
      <Text>&copy; {new Date().getFullYear()}  ArthaPravah.com</Text>
    </Box>
    </Box>
    <Loader />
  </Box>
       
   
  )
}





const NAV_ITEMS = [
  {
    label: 'Fixed Income Calculators',
    children: [
      {
        label: 'Fixed Deposit',
        href: '/calculators/fd',
      },
      {
        label: 'Recurring Deposit', 
        href: '/calculators/rd',
      },
      {
        label: 'PPF', 
        href: '/calculators/ppf',
      },
      {
        label: 'EPF', 
        href: '/calculators/epf',
      }
      
    ],
  },
  
]