import dateformat from 'dateformat';
import { ToWords } from 'to-words';
export const formatCurrency = (value) => {
	if(value===0) return '-'
    return value.toLocaleString('en-IN', { style: 'currency', currency: 'INR', maximumFractionDigits: 0 });
}
export const formatPercentage = (value) => {
    return `${value} %`;
}

export const formatDate= (value) => {
    return dateformat(value,'dd-mmm-yyyy');
}
export const getLastDateOfPreviousFY=(currentDate)=>{
	let currentYear =currentDate.getFullYear();
	let currentMonth =currentDate.getMonth();
	if(currentMonth === 0 || currentMonth === 1 || currentMonth === 2){
		currentYear = currentYear -1;
	}
	let asOfDate =	new Date(currentYear, 2,31);
	return dateformat(asOfDate,'dd-mmm-yyyy')	
}	

export const compareDates = (date1, date2) => {
    
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);

    if(date1===date2)
        return 0;
    else if(date1<date2)
        return -1;
    else
        return 1

    
  }

  export const getMaturityDateHelperText = (maturityDate,startDate,maturityAsOfDate) => {
	const toWord = new ToWords();
	let helperText =toWord.convert(maturityAsOfDate,'INR')
	if(compareDates(maturityDate,new Date())===-1){
		helperText = 'Maturity Date is in Past'
	}else if(compareDates(new Date(startDate),new Date())===1){
		helperText = 'Start Date is in Future'
	}
	return helperText
  }