import { AbsoluteCenter, Box, Modal, ModalBody, ModalContent, ModalOverlay, Spinner } from "@chakra-ui/react";
import React from "react";
import { loaderState } from "../State/Atoms";
import { useRecoilValue } from "recoil";

export const Loader=()=> {
    const isLoading = useRecoilValue(loaderState);
    

    return (
        <Box position='center' >
            <AbsoluteCenter  axis='both'>
                
                <Modal 
                 closeOnOverlayClick={false} isOpen={isLoading} isCentered  >
                    <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(1px) '/>
                    <ModalContent  justifyContent={'center'} alignItems={'center'} bg={'#dadada'}>
                    
                    <ModalBody  >
                        <Spinner
                            thickness='7px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='teal.500'
                            size='xl'
                            position='center' />
                    </ModalBody>

                   
                    </ModalContent>
                </Modal>
            </AbsoluteCenter>
      </Box>
    )
  }