// 'use client'

import {
    Box,
    Flex,
    Text,
    Stack,
    Icon,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    useColorModeValue,
    useDisclosure,
  } from '@chakra-ui/react'
  import {
    ChevronDownIcon,
  } from '@chakra-ui/icons'
import { Link } from 'react-router-dom'
export const DesktopNav = ({items}) => {
    const linkColor = useColorModeValue('white.600', 'gray.200')
    const linkHoverColor = useColorModeValue('white.700', 'white')
    const popoverContentBgColor = useColorModeValue('teal.600', 'gray.800')
    const borderColor = useColorModeValue('gray.800', 'white')
    const { onClose,onOpen,isOpen } = useDisclosure();

  
    return (
      <Stack direction={'row'} spacing={4}>
        {items.map((navItem) => (
          <Box key={navItem.label} mt={2} >
              
            <Popover trigger={'click'} placement={'bottom-start'} isOpen={isOpen} onOpen={onOpen} onClose={onClose}  >
              <PopoverTrigger>
                <Box
                  as="a"
                  p={0}
                  href={navItem.href ?? '#'}
                  fontSize={'md'}
                  fontWeight={500}
                  
                  color={linkColor}
                  _hover={{
                    textDecoration: 'none',
                    color: linkHoverColor,
                  }}>
                  {navItem.label}
                  {navItem.children &&<Icon  w={5} h={5} as={ChevronDownIcon} />}
                </Box>
              </PopoverTrigger>
  
              {navItem.children && (
                <PopoverContent
                  border={1}
                  borderColor={borderColor}
                  boxShadow={'sm'}
                  bg={popoverContentBgColor}
                  p={4}
                  rounded={'sm'}
                  width="full"
                 >
                   <PopoverBody>
                  <Stack >
                    {navItem.children.map((child) => (
                      <DesktopSubNav key={child.label} onClose={()=>onClose()} {...child} />
                    ))}
                  </Stack>
                  </PopoverBody>
                </PopoverContent>
              )}
            </Popover>
          </Box>
        ))}
      </Stack>
    )
  }
  
  const DesktopSubNav = ({ label, onClose,href }) => {
   
    return (
      <Box
        as="a"
        href={href}
        role={'group'}
        display={'block'}
        width={'full'}
        
        rounded={'sm'}
       
        >
        <Stack direction={'row'} align={'center'}>
          <Box>
            <Text
              transition={'all .3s ease'}
              _groupHover={{ fontWeight: 700 }}
              fontWeight={500}>
                <Link onClick={onClose} to={href}>{label}</Link>
             
            </Text>
           
           
          </Box>
          <Flex
            transition={'all .3s ease'}
            transform={'translateX(-10px)'}
            opacity={0}
            _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
            justify={'flex-end'}
            align={'center'}
            flex={1}>
            {/* <Icon color={linkHoverColor} w={5} h={5} as={ChevronRightIcon} /> */}
          </Flex>
        </Stack>
      </Box>
     
    )
  }
  