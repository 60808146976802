import { Flex, Image } from "@chakra-ui/react"
import img from './comingsoon.png';

export const ComingSoon = () => {

    return (
        <Flex flex={{ base: 1 }} justify={{ base: 'center' }}>
            <Image src={img} alt="Coming Soon" />
        </Flex>
    )
}