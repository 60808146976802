const FDCalculatorFormDef = ()=> {
	return {
		title: 'FD Calculator',
		titleClass:'fa fa-calculator',
		description:'Our Fixed Deposit (FD) Calculator offers detailed year-wise projections to help you understand the growth of your investment over time. It also provides the current value of your FD if the maturity date is in the future, enabling you to assess the value of your investment as of today. This feature is designed to give you a clear and comprehensive view of your financial standing, helping you make informed decisions about your fixed deposits.',
        helperText:"In addition to tracking the growth of your investment, our calculator allows you to explore different investment scenarios by adjusting the deposit amount, interest rate, and tenure. Whether you're planning for short-term gains or long-term wealth accumulation, this tool provides the insights you need to optimize your financial strategy.",
		gridStyle: {sm:1, md:4},
		fields:[
				
                            {
                                'type': 'number',
                                'id': 'principalAmount',
                                'required':true,
                                'label':'Principal Amount',
                                'placeholder': 'Principal Amount',							
                                'validators':[
                                    {
                                        'name':'required',										
                                        'errorMessage':'Required Field'
                                    },
                                    {
                                        'name':'min',
                                        'value':'1000',
                                        'errorMessage':'Amount should be greater than 1000'
                                    }
                                ]
                            },
							
							{
								
								'type': 'decimal',
								'id': 'interestRate',
								'required':true,
								'label':'Interest Rate',
								'placeholder': 'Interest Rate',									
								'validators':[
									{
										'name':'required',										
										'errorMessage':'Required Field'
									},
									{
										'name':'min',
										'value':'0.01',
										'errorMessage':'Interest Rate should be in the range of 0.01 to 15'
									},
									{
										'name':'max',
										'value':'15',
										'errorMessage':'Interest Rate should be in the range of 0.01 to 15'
									},
									
								]
							},
							
							{
								
								'type': 'date',
								'id': 'startDate',
								'required':true,
								'label':'Start Date',
								'placeholder': 'Start Date',							
								'validators':[
									{
										'name':'required',										
										'errorMessage':'Required Field'
									}
								]
							},
							{
								'size': {'xs':'12','sm':'12','md':'2'},
								'type': 'number',
								'required':true,
								'id': 'duration',
								'label':'Tenure (In Days)',
								'placeholder': 'Tenure (In Days)',							
								'validators':[
									{
										'name':'required',										
										'errorMessage':'Required Field'
									},
									{
										'name':'min',
										'value':'7',
										'errorMessage':'The tenure must be between 7 and 3650 days.'
									},
									{
										'name':'max',
										'value':'3650',
										'errorMessage':'The tenure must be between 7 and 3650 days.'
									},
								]
							}
					]
				
	}
}
export default FDCalculatorFormDef;