import { ChevronRightIcon } from '@chakra-ui/icons'
import {
    Box,
    Text,
    Stack,
    Collapse,
    Icon,
    useColorModeValue,
    useDisclosure,
  } from '@chakra-ui/react'

  

export const MobileNav = ({items}) => {
    const navBarBGColor = useColorModeValue('teal.600', 'gray.800')
   
    return (
  
      <Stack bg={navBarBGColor} p={4} display={{ md: 'none' }} >
        {items.map((navItem) => (
          <MobileNavItem key={navItem.label} {...navItem} />
        ))}
      </Stack>
    )
  }
  
  const MobileNavItem = ({ label, children, href }) => {
    const navBarColor = useColorModeValue('white', 'white')
    const { isOpen, onToggle } = useDisclosure()
  
    return (
      <Stack spacing={4} onClick={children && onToggle}>
          
        <Box
          py={2}
          as="a"
          href={href ?? '#'}
          justifyContent="space-between"
          alignItems="center"
          _hover={{
            textDecoration: 'none',
          }}>
          <Text fontWeight={600} color={navBarColor}>
            {label}
            {children && (
            <Icon
              as={ChevronRightIcon}
              transition={'all .25s ease-in-out'}
              transform={isOpen ? 'rotate(90deg)' : ''}
              w={6}
              h={6}
            />
          )}
          </Text>
          
        </Box>
  
        <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
          <Stack
            mt={2}
            pl={4}
            
            align={'start'}>
            {children &&
              children.map((child) => (
                <Box as="a" key={child.label} py={2} href={child.href} color={navBarColor}>
                  {child.label}
                </Box>
              ))}
          </Stack>
        </Collapse>
      </Stack>
    )
  }