
const EPFCalculatorFormDef = (lastDateOfPreviousFY)=> {
	return {
		title: 'EPF Calculator',
		titleClass:'fa fa-calculator',
		description: 'Our Employee Provident Fund (EPF) Calculator is designed to help you estimate the growth of your EPF savings over time. By allowing you to input expected annual growth rates, this tool provides detailed year-wise projections, helping you understand how your EPF balance can accumulate throughout your career. This feature is ideal for planning your retirement and making informed decisions about your financial future.',
		helperText:'By allowing you to adjust factors such as the monthly contribution amount, previous financial year-end closing balance, and the remaining service period in months, our EPF Calculator empowers you to explore various scenarios and assess the impact of different strategies on your retirement savings. Whether you are at the beginning of your career or approaching retirement, this tool offers valuable insights to help you optimize your EPF contributions.',
		gridStyle: {sm:1, md:4},
		fields:[
			{
				'type': 'number',
				'id': 'employeeContributionAmount',
				'required':true,
				'label':'Monthly Contribution',
				'placeholder': 'Employee + Employer + Voluntary Contribution',							
				'validators':[
					{
						'name':'required',										
						'errorMessage':'Required Field'
					},
					{
						'name':'min',
						'value':'1800',
						'errorMessage':'Minimum Contribution is 1800'
					}
				]
			},
			{
				'type': 'number',
				'id': 'terms',
				'required':true,
				'label':'Terms (In Months)',
				'placeholder': 'Remaining Service Period In Months',							
				'validators':[
					{
						'name':'required',										
						'errorMessage':'Required Field'
					},
					{
						'name':'min',
						'value':'1',
						'errorMessage':'Term Should be greater than 1'
					}
				]
			},
			{
				'type': 'number',
				'id': 'balance',
				'required':true,
				'label':'Balance as of ' +  lastDateOfPreviousFY,
				'placeholder': 'Balance Amount',							
				'validators':[
					{
						'name':'required',										
						'errorMessage':'Required Field'
					},
					{
						'name':'min',
						'value':'0',
						'errorMessage':'Balance should not be less than 0'
					}
				]
			},
			{
				'type': 'number',
				'id': 'expectedGrowth',
				'label':'Expected Growth % ',
				'placeholder': 'Expected Growth %'							
				
			}
			
	]
	}
}
export default EPFCalculatorFormDef;