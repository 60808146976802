import React from 'react';
import {
  ChakraProvider,
  theme,
} from '@chakra-ui/react';
import { RouterProvider } from 'react-router-dom';
import  {router} from './Routes/routes'
import { RecoilRoot } from 'recoil';

function App() {
  return (
    <RecoilRoot>
    <ChakraProvider theme={theme}>
      {/* <BrowserRouter> */}
      {/* <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
          <VStack spacing={8}>
            {<Logo h="40vmin" pointerEvents="none" />
            <Text>
              Edit <Code fontSize="xl">src/App.js</Code> and save to reload.
            </Text>
            <Link
              color="teal.500"
              href="https://chakra-ui.com"
              fontSize="2xl"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn Chakra
            </Link> }
            <Layout/>
          </VStack>
        </Grid>
      </Box> */}
      <RouterProvider router={router}>
        
    </RouterProvider>
      {/* </BrowserRouter> */}
    </ChakraProvider>
    </RecoilRoot>
  );
}

export default App;
