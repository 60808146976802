'use client'

import {
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import {
    
  FcCalculator,
  FcConferenceCall,
  FcCurrencyExchange,
  FcReading,
  FcUnlock,
  FcSalesPerformance
} from 'react-icons/fc'




const Card = ({ heading, description, icon, href }) => {
  return (
    <Box
      maxW={{ base: 'full', md: '275px' }}
      w={'full'}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}>
      <Stack align={'start'} spacing={2}>
        <Flex
          w={16}
          h={16}
          align={'center'}
          justify={'center'}
          color={'white'}
          rounded={'full'}
          bg={useColorModeValue('gray.100', 'gray.700')}>
          {icon}
        </Flex>
        <Box mt={2}>
          <Heading size="md">{heading}</Heading>
          <Text mt={1} fontSize={'sm'}>
            {description}
          </Text>
        </Box>
        {/* <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
          Learn more
        </Button> */}
      </Stack>
    </Box>
  )
}



export const Home=()=> {
  const features= cardLists;
  return (
    <Box p={4}>
      <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>
            Welcome to Artha Pravah
        </Heading>
        <Heading fontSize={{ base: 'xl', sm: '2xl' }} fontWeight={'light'}>
        Empowering Financial Awareness
        </Heading>
        <Text color={'gray.600'} fontSize={{ base: 'sm', sm: 'lg' }}>
            In a world where financial literacy is crucial, our mission is to provide you with the tools and knowledge you need to make informed financial decisions. Our financial calculator website is designed to cater to the unique needs of the Indian audience, offering a suite of powerful tools and resources to help you navigate your financial journey.
        </Text>
      </Stack>
      
      <Heading mt= {12} fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'} textAlign={'center'}>
            Why Choose Us?
        </Heading>

      <Container maxW={'5xl'} mt={12}>
      
        <Flex flexWrap="wrap" gridGap={6} justify="center">
        {
            features.map((card) => (
            <Card key={card.heading} {...card} />
            ))
        }
          
        </Flex>
      </Container>
    
    </Box>
  )
}

const cardLists = [
  {
    heading: 'Comprehensive Financial Tools',
    description: "From basic calculations to advanced financial planning, our calculators cover a wide range of needs. Whether you're planning for a home loan, saving for your child's education, or investing for retirement, we have the right tool for you.",
    icon: <Icon as={FcCalculator} w={10} h={10} />,
  },
  {
    heading: 'Tailored for Indian Financial Landscape',
    description: "Our calculators are designed with the Indian market in mind, incorporating local tax laws, interest rates, and financial products. This ensures that the results you get are relevant and accurate for your specific financial situation.",
    icon: <Icon as={FcCurrencyExchange} w={10} h={10} />,
  },
  {
    heading: 'Take Control of Your Financial Future',
    description: "Start your journey towards financial empowerment today. Explore our tools, educate yourself, and make decisions that secure your financial future. With our website, you're not just calculating numbers; you're building a brighter, more secure future for yourself and your family",
    icon: <Icon as={FcSalesPerformance} w={10} h={10} />,
}
  ,
  {
    heading: 'Educative Resources',
    description: "Beyond calculators, we provide a wealth of articles, tips, and guides to enhance your financial knowledge. Stay updated with the latest trends and best practices in personal finance to make well-informed decisions.",
    icon: <Icon as={FcReading} w={10} h={10} />,
  },
  {
    heading: 'Free and Accessible',
    description: "We believe that financial awareness should be accessible to everyone. Our tools and resources are free to use, ensuring that you can achieve your financial goals without any barriers.",
    icon: <Icon as={FcUnlock} w={10} h={10} />,
 },
 {
    heading: 'User-Friendly Interface',
    description: "Our website is intuitive and easy to navigate, making it simple for anyone to use, regardless of their financial expertise. Step-by-step guides and clear instructions ensure you can make the most of our tools.",
    icon: <Icon as={FcConferenceCall} w={10} h={10} />,
  }
]

