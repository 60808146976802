import { Stat, StatGroup, StatHelpText, StatLabel, StatNumber } from "@chakra-ui/react"

export const CalculationStats = ({data})=>{
   
    return(
        <StatGroup direction={{ base: 'column', md: 'row' }} marginTop={50} marginBottom={50}>

            {data.map((item, index) => (
               
                <Stat key={index}>
                    <StatLabel>{item.name}</StatLabel>
                    <StatNumber>{item.formatter ? item.formatter(item.value): item.value}</StatNumber>
                    {item.helperText && <StatHelpText>{item.helperText}</StatHelpText>}
                    
                </Stat>
                
            ))}
        </StatGroup>
    )
}