import { Box, Card, CardBody, CardHeader, Flex, SimpleGrid, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import PPFCalculatorFormDef from './../Forms/Definations/PPFFormDefination'
import { AppForm } from '../Forms/AppForm';
import { useRecoilState } from 'recoil';
import { loaderState } from '../State/Atoms';
import { BASE_URL } from '../Common/Constants';
import axios from 'axios';
import { useState } from 'react';
import { formatCurrency, formatPercentage, getLastDateOfPreviousFY } from '../Common/Utils';
import { Doughnut } from 'react-chartjs-2';
import {Chart, ArcElement,Tooltip,Legend} from 'chart.js'
import { ToWords } from 'to-words';
import { CalculationStats } from '../Common/CalculationStats';
import { CalculatorHeader } from '../Common/CalculatorHeader';
Chart.register(ArcElement);
Chart.register(Tooltip);
Chart.register(Legend);


export const PPFCalculator = () => {
    const [, setLoader] = useRecoilState(loaderState);
    const [epfData, setEPFData] = useState([]);
    const [chartData,setChartData] = useState({})
    const [dataArray1,setDataArray1] = useState([])
    const [dataArray2,setDataArray2] = useState([])
    const toWords = new ToWords();

    const onReset=()=>{
        setEPFData([]);
        setChartData({})
        setDataArray1([])
        setDataArray2([])
    }
    
    const onSubmit = async(data) => {
        setLoader(true);
        try{
            const response = await axios.post(`${BASE_URL}/fi/calculators/ppf`, data)
            setEPFData(response.data);
            const investmentAmount = response.data.transactionList.reduce((acc, item) => acc + item.transactionAmount, 0);
            const interestAmount = response.data.transactionList.reduce((acc, item) => acc + item.interestAmount, 0);
            const maturityAmount = response.data.maturityAmount;
            const interestRate = response.data.interestRate;
            setChartData({investmentAmount,interestAmount,maturityAmount,interestRate})
            
            setDataArray1([
                { name: 'Invested Amount',value: investmentAmount,formatter:formatCurrency,helperText:toWords.convert(investmentAmount,'INR')},
                { name: 'Interest Amount',value: interestAmount,formatter:formatCurrency,helperText:toWords.convert(interestAmount,'INR')},
               
            ])
            setDataArray2([
                
                { name: 'Maturity Amount',value: maturityAmount,formatter:formatCurrency,helperText:toWords.convert(maturityAmount,'INR')},
                { name: 'Interest Rate',value: interestRate,formatter:formatPercentage},
            ])
        
        }catch(e){
           
        }

        setLoader(false);
       
       
    }
    const ppfFormDef = PPFCalculatorFormDef(getLastDateOfPreviousFY(new Date()));
   
    
  

    return (
        <>
        <Card>
            <CardHeader>
               <CalculatorHeader title="Public Provident Fund Calculator" 
                highlights={["Government-Backed","Tax Benefit","Long-Term","Flexible","Extension"]} />
            </CardHeader>
            <CardBody>
                <Stack  spacing='5'>
                    <Text fontSize='md' color={'black.500'}>{ppfFormDef.description}</Text>
                    <Text mb={5} fontSize='md' color={'black.500'}>{ppfFormDef.helperText}</Text>
                    <AppForm formDef={ppfFormDef} onFormSubmit={onSubmit} onFormReset={onReset}/>
                </Stack>
            </CardBody>
        </Card>
        
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 2}}>
        { epfData && epfData.transactionList && epfData.transactionList.length > 0 && 
            <Box mt={10} mb={2} size="sm"  >
                <Flex width={["100%", "100%", "50%"]} height={["200px", "250px", "350px"]} >
                    <DetailChart data={chartData} />
                </Flex>
            </Box> }
            { dataArray1 && dataArray2  && dataArray1.length > 0 && dataArray2.length > 0 && <Box>
                <CalculationStats data={dataArray1} /> 
                <CalculationStats data={dataArray2} /> 

            </Box>}
            
            
        </SimpleGrid>
        <Box mt={2} mb={2}>
        { epfData && epfData.transactionList && epfData.transactionList.length > 0 && <PPFDetails data={epfData}/>}
        </Box>
        
       
        </>

    
    )
}

const DetailChart=({data})=>{
    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: 'start',
                labels: {
                    generateLabels: (chart) => {
                        const datasets = chart.data.datasets;
                        return datasets[0].data.map((data, i) => ({
                          text: `${chart.data.labels[i]} : ${formatCurrency(data)}`,
                          fillStyle: datasets[0].backgroundColor[i],
                          index: i
                        }))
                    },
                    textAlign: 'right'
                }
                
               
                    
            }
        }
    }
    
    const doughnut = {

       
        labels: [
          'Invested Amout',
          'Interest Amount'
         
        ],
        datasets: [
          {
            data: [data.investmentAmount, data.interestAmount],
            backgroundColor: [
              '#FF6384',
              '#17a2b8'
              
            ],
            hoverBackgroundColor: [
              '#FF6384',
              '#17a2b8',
             
            ],
          }],
      };
    return (
        <Doughnut data={doughnut} options={options}  />
    )
}


const PPFDetails = ({data})=>{
    const headers= [
        {
            Header: 'Financial Year',
            isNumeric: false,
        },
        {
            Header: 'Opening Balance',
            isNumeric: true
        },
        {
            Header: 'Invested Amount',
            isNumeric: true
        },
        {
            Header: 'Interest Amount',
            isNumeric: true
        },
        {
            Header: 'Closing Balance',
            isNumeric: true
        }
    ]
    
    return (
        <TableContainer>
  <Table size='sm' variant={'striped'} colorScheme='gray' >
    <Thead>
      <Tr>  
        {headers.map((header) => (
          <Th key={header.Header} isNumeric={header.isNumeric}>{header.Header}</Th>
        ))}
      </Tr>
    </Thead>
    <Tbody>
      {data.transactionList.map((transaction) => (
        <Tr key={transaction.financeYear}>
          <Td>{transaction.financeYear}</Td>
          <Td isNumeric>{formatCurrency(transaction.openingBalance)}</Td>
          <Td isNumeric>{formatCurrency(transaction.transactionAmount)}</Td>
          <Td isNumeric>{formatCurrency(transaction.interestAmount)}</Td>
          <Td isNumeric>{formatCurrency(transaction.closingBalance)}</Td>
        </Tr>
      ))}
      
    </Tbody>
    
  </Table>
</TableContainer>
    )
}