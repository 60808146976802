import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import DefaultLayout from "../Layout/DefaultLayout";
import { Home } from "../Pages/Home";
import { EPFCalculator } from "../Calculators/EPF";
import { PPFCalculator } from "../Calculators/PPF";
import { ComingSoon } from "../Pages/ComingSoon";
import { FDCalculator } from "../Calculators/FD";
import { RDCalculator } from "../Calculators/RD";

export const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<DefaultLayout />}>
        <Route path="" element={<Home/>} />
        <Route path="/calculators/fd" element={<FDCalculator/>} />
        <Route path="/calculators/epf" element={<EPFCalculator />} />
        <Route path="/calculators/ppf" element={<PPFCalculator />} />
        <Route path="/calculators/rd" element={<RDCalculator />} />
        <Route path="*" element={<ComingSoon />} />
      </Route>
    )
  );



