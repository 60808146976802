import {
    Box,
    Flex,
    IconButton,
    Stack,
    Collapse,
    useColorModeValue,
    Image,
    useColorMode,
    useDisclosure,
  } from '@chakra-ui/react'
  import {
    HamburgerIcon,
    CloseIcon,
  } from '@chakra-ui/icons'
import { DesktopNav } from './DesktopNavBar'
import { MobileNav } from './MobileNavBar'

import logo from './logo3.png';
import { Link } from 'react-router-dom';
export const NavBar = ({items}) => {
    const { isOpen, onToggle } = useDisclosure()
    useColorMode()
    const linkColor = useColorModeValue('white', 'gray.200')
    return (
        <Box>
          <Flex
            bg={useColorModeValue('teal.900', 'gray.800')}
            color={useColorModeValue('white', 'white')}
            minH={'60px'}
            py={{ base: 1 }}
           
            borderBottom={1}
            borderStyle={'solid'}
            borderColor={useColorModeValue('gray.200', 'gray.900')}
            align={'center'}>
            <Flex
              flex={{ base: 1, md: 'auto' }}
              ml={{ base: -2 }}
              display={{ base: 'flex', md: 'none' }}>
              <IconButton
                onClick={onToggle}
                colorScheme='teal.900'
                _hover={{ bg: 'teal.600' }}
                icon={isOpen ? <CloseIcon w={3} h={3} color={linkColor} /> : <HamburgerIcon w={5} h={5}  color={linkColor}/>}
                variant={'ghost'}
                aria-label={'Toggle Navigation'}
              />
            </Flex>
            <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
              
                {/* <Avatar name='Artha Sanchay' size={'md'} bg={useColorModeValue('white', 'white')}/> */}
                <Link to="/">
                  <Image src={logo} alt='Artha Pravah' height={'45px'} />
                </Link>
             
    
              <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
                <DesktopNav items={items} />
              </Flex>
            </Flex>
    
            <Stack
              flex={{ base: 1, md: 0 }}
              justify={'flex-end'}
              direction={'row'}
              spacing={6}>
             
            </Stack>
          </Flex>
    
          <Collapse in={isOpen} animateOpacity>
            <MobileNav  items = {items}/>
          </Collapse>
        </Box>
      )
    }

    