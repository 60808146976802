import {SimpleGrid,FormControl,FormLabel,Input,FormErrorMessage, Button, Select } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';






const createValidations =(field)=>{
    const obj = {};
    const validators = field.validators
    if(validators && validators.length > 0)
    validators.forEach(validator=>{
        if(validator.name === "required"){
            obj[validator.name] =validator.errorMessage
        }else{
            obj[validator.name] = {message: validator.errorMessage,value:validator.value}
        }
        
    });
    if(field.type === "number"){
        obj['pattern']= {value:/^[0-9]*$/, message: "Only numbers are allowed"}
    }
    if(field.type === "decimal"){
        obj['pattern']= {value:/^\d+(\.\d{1,2})?$/, message: "Number upto two decimal places are allowed."}
    }
    return obj;
}
const createFormInput = (field,register,errors,control)=>{
    const validators = createValidations(field)
   if(field.type==="select"){
        return (
            <Controller
            control={control}
            name={field.id}
            rules={validators}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { error }
            }) => (
              <FormControl isInvalid={errors[field.id]}  isRequired={field.required} key={field.id}>
                <FormLabel htmlFor={field.id} fontSize={'xs'}>{field.label}</FormLabel>
    
                <Select
                  name={name}
                  id={name}
                  ref={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  options={field.options}
                  placeholder="--- Select ---"
                  fontSize={'xs'}
                >
                    {
                        field.options.map((option)=>{
                            return <option fontSize={'xs'} value={option.value}>{option.label}</option>
                        })
                    }
                </Select>
    
                <FormErrorMessage>{error && error.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        )
        
    }
    else{
        const type = field.type ? field.type : "text";

        return (
            <FormControl isInvalid={errors[field.id]}  isRequired={field.required} key={field.id}>
                <FormLabel htmlFor={field.id} fontSize={'xs'}>{field.label}</FormLabel>
                <Input id={field.id} placeholder= {field.placeholder}  
                    _placeholder={{ color: 'gray.500', fontSize: 'xs'}}
                    focusBorderColor='teal.200'
                    {...register(field.id, validators)}
                    type = {type}
                    ></Input>
            
                <FormErrorMessage>  
                    {errors[field.id] && errors[field.id].message}
                </FormErrorMessage>
            </FormControl>)
        
    }
        
}

export const AppForm=({formDef,onFormSubmit,onFormReset})=>{

    const {
        handleSubmit,
        register,
        reset,
        control,
        formState: { errors,isSubmitting,   },
      } = useForm({mode:'all'});
      
      const onResetClick = ()=>{
        reset();
        onFormReset();
      }


return(
    <>
    <form  noValidate onSubmit={handleSubmit(onFormSubmit)} >
        <SimpleGrid columns={formDef.gridStyle} spacing={2}>
            {
                formDef.fields.map((field)=>{
                    
                    return (
                       
                            createFormInput(field,register,errors,control)
                       
                           
                        
                    )
                })
                
            }
        
     
      </SimpleGrid>
      
      <Button mt={4} colorScheme='teal' isLoading={isSubmitting}  type='submit'  >Submit</Button>
      <Button mt={4} ml={2} colorScheme='red' onClick={()=>onResetClick()}  >Reset</Button>
    </form>
        </>

   
)

}